import React from "react";
import S5BHeaderSection from "./S5BHeaderSection";
import S5BFooterSection from "./S5BFooterSection";
import newsDesk from '../../assets/images/nesw-dark.png';
import { Container, Image, Row, Col, Nav, Tab, Accordion } from "react-bootstrap";
import '../../assets/css/program_breakdown_page.css';


const S5BProgramBreakdown = () => {
    return(
        <>
            <S5BHeaderSection/>
            <Container fluid>
            <Row>
		<Col>
        <Container>
			<Row className="my-4">
				<Col sm={7} className="d-flex align-items-center">
					<div className="my-4 py-3 black_text">
						<h1 className="all_main_heads">Program Breakdown</h1>
						<p className="py-3">The program will be run as a combination of remote learning in a Web-based environment, and an onsite period designed to tackle the practical training and the Final Field Project. Practicals are scheduled to take an intensive period of five weeks, followed by the four-week Final Field Project.</p>
						 {/* <h5 className="red_text redfont">Sept. 2, 2024 – Feb. 28, 2025</h5> */}
						 <p>Participants will have an elapsed period of 21 weeks in which students are expected to complete the 13 weeks of remote learning.</p>
					</div>
				</Col>
				
				<Col sm={5} className="d-flex align-items-center justify-content-center">
					<Image src={newsDesk} className="img-fluid"></Image>
				</Col>
			</Row>
        </Container>
	<Container className="mb-5">
    <Tab.Container id="left-tabs-example" defaultActiveKey="theory">
      <Row>
        <Col>
        <Nav variant="tabs" fill>
            <Nav.Item className="w-50">
              <Nav.Link eventKey="theory" className="p-sm-4 p-xs-1 border-0 tabsClass">Theory</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-50">
              <Nav.Link eventKey="practicals" className="p-sm-4 p-xs-1 border-0 tabsClass">Practicals + Final Field Project</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="theory" className="bg-dark-blue">
                    <p className="text-white my-4 w-80">The IIC Academy has integrated the theoretical component of the program under a Learning Management System platform, which will allow participants to follow the modules and study from the comfort of their Homes or offices only requiring a computer and internet access.</p>
                    <Accordion>
                    <Accordion.Item className="my-4 border-0" eventKey="0">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-1</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0 d-flex align-items-center">
                                        <h4 className="mb-0 tab_head">Introduction to Hydrography</h4>
                                      
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={12} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the introduction to the program.</p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="1">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-2</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0 d-flex align-items-center">
                                        <h4 className="mb-0 tab_head">Computation Tools</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Review of
                                            math/programming concepts in application
                                            to hydrographic survey needs. Creation of
                                            simple processing tools in Python for file
                                            conversion and scripting applied to
                                            hydrographic data handling.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="2">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-3</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0 d-flex align-items-center">
                                        <h4 className="mb-0 tab_head">Nautical Science</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Nautical science,
                                            cartographic concepts, and boatmanship
                                            field time.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="3">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-4</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Environment Science</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Elements of Geology,
                                            Geophysics, Oceanography and
                                            Meteorology.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="4">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-5</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Positioning</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Theoretical background on
                                            positioning in hydrography and an
                                            introduction to land surveying.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="5">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-6</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Underwater Acoustics</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Acoustic and
                                            transducer principles applied to Single
                                            Beam Echosounders, Side Scan Sonar,
                                            Multibeam Echosounders and swath
                                            systems. Other survey techniques.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="6">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-7</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Water Levels</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Tide and tidal current theory.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="7">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-8</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Quality Control</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Principles and practice of
                                            Quality Assurance/Quality Control in
                                            hydrographic data acquisition.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="8">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-9</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Remote Sensing</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Remote sensing principles
                                            and practice.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="9">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-10</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Hydrographic Practice</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Practice of
                                            hydrography and principles of product
                                            liability and law of the sea.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="10">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">Module-11</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Hydrographic Data Management</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p>Management of hydrographic data from
                                            acquisition to product production.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    </Accordion>  
            </Tab.Pane>
            <Tab.Pane eventKey="practicals" className="bg-dark-blue">  
                <Accordion>
                    <Accordion.Item className="my-4 border-0" eventKey="0">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">5 weeks</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Practicals<i className="fas fa-angle-down rotate-icon"></i></h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p> Practicals are the hands on component of the program. You will execute practical tasks and exercises, from chart design to data capture, and finishing with the required chart validation checks. The goal is to put to use all the theory learned through the remote learning period.</p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="1">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0">
                                        <h4 className="mb-0 tab_head text-white">4 weeks</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Comprehensive Field Project<i className="fas fa-angle-down rotate-icon"></i></h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={12} sm={9} className="p-0">
                                        <div className="p-4"><p> To complete the program, students will be tasked with a Final Field Project, in which they will have to plan and compile a 
                                                chart and execute the proper validation checks to guarantee its integrity and usability.</p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                </Accordion>  
            </Tab.Pane>
          </Tab.Content>
		</Col>
	</Row>
    </Tab.Container>
    </Container>
    </Col>
	</Row>	
	<div className="lodaer_mask" id="loaderMask">
		<div className="lds-facebook"><div></div><div></div><div></div></div>
	</div>
    </Container>
            <S5BFooterSection/>
        </>
    )
}
export default S5BProgramBreakdown;